body {
  background-color: blanchedalmond;
  margin: 0;
}
.no-button {
  background-color: blanchedalmond;
  border: none;
  font-size: 1em;
}

.steps {
  line-height: 30px; 
}

.bold-selected {
  font-weight: bolder;
}
